import React, { Component } from 'react';
import * as Colyseus from "colyseus.js";
import { Route } from 'react-router';
import QRCode from 'qrcode.react';
import Lottie from 'react-lottie';
import { Howl, Howler } from "howler";
//import Confetti from 'react-confetti';
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import Menu from "components/Menu";

import { testRoomState } from "constants/TestData";


import Player from "components/Player";
import Tutorial from "components/Tutorial";

import getAvatarById from "constants/avatars";

import "animate.css";
import styles from 'components/HostStyles.module.scss';

import logo from "images/logo.png";
import mutedIcon from "images/Host/lobby_muted.png";
import unmutedIcon from "images/Host/lobby_unmuted.png";
import fullscreenIcon from "images/Host/lobby_fullscreen.png";
import helpIcon from "images/Host/lobby_help.png";
import checkStrip from "images/checkstrip.png";
import stopwatchButton from "images/StopwatchButton.png";
import stopwatchFace from "images/StopwatchFace.png";
import bigWatchButton from "images/BigWatchButton.png";
import bigWatchFace from "images/BigWatchFace.png";
import roundBG from "images/round-BG.png";
import warningPink from "images/warning-pink.png";
import warningBlack from "images/warning-black.png";
import flagPink from "images/flag-pink.png";
import flagBlack from "images/flag-black.png";
import trophy from "images/trophy.png";

import BellSFX from "audio/Bell.mp3";
import CelebrationSFX from "audio/Celebration.mp3";
import DangerZoneSFX from "audio/DangerZoneV2.mp3";
import LongTimerSFX from "audio/LongTimer.mp3";
import MainMusic from "audio/MainMusic.mp3";
import NewRule1SFX from "audio/NewRule1.mp3";
import NewRule2SFX from "audio/NewRule2.mp3";
import NewRule3SFX from "audio/NewRule3.mp3";
import PointPopSFX from "audio/PointPop.mp3";
import ReadySetGoSFX from "audio/ReadySetGo.mp3";
import ReadyUpSFX from "audio/ReadyUp.mp3";
import RoundMusic from "audio/RoundMusic.mp3";
import SingleTimerTickSFX from "audio/SingleTimerTick.mp3";
import VotingPopSFX from "audio/VotingPop.mp3";
import WooshSFX from "audio/Woosh.mp3";
import LoudBellSFX from "audio/Loud-Bell.mp3";
import GuessingPointSFX from "audio/GuessingPoint.mp3";
import PassingCardSFX from "audio/PassingCard.mp3";

const fullscreenAvailable = document.fullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;

var audio = {
    BgMusic: {
        import: MainMusic,
        loaded: null,
        volume: 0.5,
        loop: true,
    },
    RoundMusic: {
        import: RoundMusic,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    Bell: {
        import: BellSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    LoudBell: {
        import: LoudBellSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
Celebration: {
        import: CelebrationSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    DangerZone: {
        import: DangerZoneSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    LongTimer: {
        import: LongTimerSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRule1: {
        import: NewRule1SFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRule2: {
        import: NewRule2SFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    NewRule3: {
        import: NewRule3SFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    PointPop: {
        import: PointPopSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ReadySetGo: {
        import: ReadySetGoSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    ReadyUp: {
        import: ReadyUpSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    SingleTimerTick: {
        import: SingleTimerTickSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    SingleTimerTickQuieter: {
        import: SingleTimerTickSFX,
        loaded: null,
        volume: 0.5,
        loop: false,
    },
    VotingPop: {
        import: VotingPopSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
    Woosh: {
        import: WooshSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
GuessingPoint: {
        import: GuessingPointSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
PassingCard: {
        import: PassingCardSFX,
        loaded: null,
        volume: 1,
        loop: false,
    },
};


const GameStates = {
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    SelectTeamMethod: "select_team_method",
    SelectTeams: "select_teams",
    ConfirmTeams: "confirm_teams",
    PlayingIdle: "playing_idle",
    Playing: "playing",
    RuleBreakVote: "rule_break_vote",
    CorrectCardVote: "correct_card_vote",
    NewRuleVote: "new_rule_vote",

    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "cant_say_umm";


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            myId: null,
            roomState: null,
            redirect: null,
            reconnectTries: 0,
            connected: false,
            //connected: true,
            reconnectionToken: "",
            muted: false,
            menuOpen: false,
            tickedSkipTutorial: false,
            gameBegun: false,
            musicPlaying: false,

            gotLocationPing: true,
            connectionIssue: false,

            players: [],
            showPlayers: true,
            gameState: GameStates.Loading,

            doingTutorial: false,
            showTutorial: false,
            skipTutorialCount: 0,
            showStartButtons: false,
            showPlayAgainButtons: false,

            moveLogo: false,
            showLines: false,

            showTeamMethodSection: false,
            showChooseTeamSection: false,
            teamSelectionType: "", // "random", "manual",
            showTeamsConfirmation: false,
            showTeamSelection: false,

            teams: [],
            describingTeamId: "",
            newRuleTeamId: "",
            currentRulesVote: [],
            timer: 0,
            newRuleZones: [],
            pointsToWin: 0,
            dangerZoneStart: 0,

            showTeamError: false,
            teamError: "",

            showDescriber: false,
            showDinger: false,
            showNotEnoughPlayersSection: false,
            showReadyCheck: false,

            showRoundView: false,
            centerSmallText: "",
            centerTitle: "",
            shrinkCenterBox: false,

            showTimesUp: false,

            showRuleBreakVote: false,

            showCorrectCardVote: false,
            correctCards: [],

            showNewRuleVoteSection: false,
            collapseRuleCards: false,
            showPickRuleTitle: false,
            showNewRuleTitle: false,
            showNewRulePlayers: false,
            showNewRuleCard: false,
            showFrontRuleCards: false,
            growNewRuleCard: false,
            newRule: {
                ruleText1: "",
                blockText: "",
                ruleText2: "",
            },
            showWinDeciderSection: false,
        };
        this.toggleMute = this.toggleMute.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    componentDidMount() {
        this.setTags();
        this.doReconnect();

        this.toggleMute(true, false);
        this.initAudio();

        setTimeout(() => {
            console.log(this.state);
        }, 10000);
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isHost', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    initAudio() {
        console.log("Init audio");
        this.preloadAudio();
        Howler.volume(0.5);
        this.playBGMusic();
    }

    preloadAudio() {
        for (let key in audio) {
            audio[key].loaded = new Howl({
                src: [audio[key].import],
                preload: true,
                loop: audio[key].loop,
                volume: audio[key].volume
            });
        }
    }

    stopAudio(audio) {
        if (audio.loaded) audio.loaded.stop();
    }

    playAudio(audio) {
        if (audio.loaded) audio.loaded.play();
    }

    pauseAudio(audio) {
        if (audio.loaded) audio.loaded.pause();
    }

    toggleFullScreen() {
        if (fullscreenAvailable) {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                let elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) {
                    elem.webkitRequestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    elem.mozRequestFullScreen();
                } else if (elem.msRequestFullscreen) {
                    elem.msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.mozExitFullscreen) {
                    document.mozExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            }
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    animatePotato(id, animation) {
        if (document.getElementById(`potato-${id}`)) {
            this.animateCSS(`#potato-${id}`, animation);
        }
    }

    animateCSS = (element, animation, prefix = 'animate__') =>
        // We create a Promise and return it
        new Promise((resolve, reject) => {
            const animationName = `${prefix}${animation}`;
            let node;
            if (typeof element === `string`) {
                node = document.querySelector(element);
            } else {
                node = element;
            }
            node.classList.add(`${prefix}animated`, animationName);

            // When the animation ends, we clean the classes and resolve the Promise
            function handleAnimationEnd(event) {
                event.stopPropagation();
                node.classList.remove(`${prefix}animated`, animationName);
                resolve('Animation ended');
            }

            node.addEventListener('animationend', handleAnimationEnd, { once: true });
        });

    toggleMenu() {
        console.log("toggle menu");
        let newVal = this.state.menuOpen == true ? false : true;
        this.setState({ menuOpen: newVal });
    }

    toggleMute(force = false, value = false) {
        let newVal;
        if (force) {
            newVal = value;
        } else {
            newVal = !this.state.muted;
        }

        Howler.mute(newVal);
        this.setState({ muted: newVal, });
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id == player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
            setTimeout(() => { // animate in timeout
                let statePlayers = [...this.state.players];
                let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                let statePlayer = { ...statePlayers[pos] };
                statePlayer.showPlayer = true;
                statePlayers[pos] = statePlayer;
                this.setState({ players: statePlayers });
            }, 500);
        }
    }

    removePlayer(id) {
        this.setState((prevState) => {
            return { players: prevState.players.filter(x => x.id != id), }
        });
    }

    getRandomElement(array) {
        return array[Math.random() * array.length >> 0];
    }

    signalStartGame = (skipTutorial) => {
        this.setState({ showStartButtons: false });
        this.state.room.send("begin_game", { skipTutorial });
    }
    goToLobby = () => {
        this.state.room.send("change_game", {});
    }
    signalNewGame = () => {
        this.setState({ showPlayAgainButtons: false, showWinnerSection: false, });
        setTimeout(() => {
            this.state.room.send("ycsu_new_game", {});
        }, 1000);
    }



    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }

    toggleSkipTutorial = (e) => {
        if (this.state.showStartButtons) {
            console.log("cb value: " + e.target.checked);
            this.setState({ tickedSkipTutorial: e.target.checked });
        }
    }

    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    clickTeamMethod(type) {
        this.state.room.send("selected_team_type", { type });
        this.setState({ showTeamMethodSection: false, });
    }

    clickTeamConfirmation(happy) {
        if (happy) {
            this.playWoosh();
            this.setState({ showChooseTeamSection: false, showTeamsConfirmation: false, });
            this.state.room.send("teams_ok", {});
        } else {
            this.playWoosh();
            this.setState({ showTeamsConfirmation: false, });
            this.state.room.send("teams_not_ok", {});
        }
    }

    sleep(time) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }


    async doStartRound() {
        this.setState({ shrinkCenterBox: false, centerTitle: "", centerSmallText: "" });
        if (this.state.showNotEnoughPlayersSection) {
            await this.sleep(1000);
            this.playWoosh();
            this.setState({ showNotEnoughPlayersSection: false });
            await this.sleep(1500);
        } else {
            await this.sleep(1000);
        }
        this.playPopSequence(250, 4, 250);
        this.setState({ showDescriber: true });
        await this.sleep(5000);
        this.playWoosh(250);
        this.setState({ showDescriber: false, });
        await this.sleep(1500);
        this.playPopSequence(250, 4, 250);
        this.setState({ showDinger: true });
        await this.sleep(5000);
        this.playWoosh(250);
        this.setState({ showDinger: false });
        await this.sleep(1500);
        this.setState({ showReadyCheck: true });
        this.state.room.send("start_ready_check", {});
    }

    async doAllReady() {
        this.stopAudio(audio.RoundMusic);
        this.setState({showReadyCheck: false, });
        await this.sleep(1500);
        //this.playWoosh();
        this.setState({ showRoundView: true, });
        await this.sleep(2000);
        this.setState({ centerTitle: this.state.roundNumber === this.state.maxRounds ? `Final Round` : `ROUND ${this.state.roundNumber}` });
        this.playAudio(audio.ReadySetGo);
        this.pauseBGMusic();
        await this.sleep(2000);
        this.setState({ centerSmallText: "READY" });
        await this.sleep(1000);
        this.setState({ centerSmallText: "STEADY" });
        await this.sleep(1000);
        this.setState({ centerSmallText: "GO", shrinkCenterBox: true });
        this.playAudio(audio.RoundMusic);
        this.state.room.send("start_round_timer", {});
    }

    async doNotEnoughPlayers(message) {
        await this.sleep(1000);
        this.playPopSequence(250, 3, 500);
        this.setState({ showNotEnoughPlayersSection: true });
        await this.sleep(1000);
        this.state.room.send("start_not_enough_players_timer", {});
    }

    getDescriber() {
        const desciber = this.state.players.find((x) => x.ycsuData.isDescriber);
        if (!desciber) return null;

        return desciber.name;

        return <>
            <div className={`${styles.potato}`}>
                <Lottie
                    options={getAvatarById(desciber.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div>
            <div className={styles.name}>{desciber.name}</div>
        </>
    }

    getDinger() {
        const dinger = this.state.players.find((x) => x.ycsuData.isDinger);
        if (!dinger) return null;

        return dinger.name;

        return <>
            <div className={`${styles.potato}`}>
                <Lottie
                    options={getAvatarById(dinger.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div>
            <div className={styles.name}>{dinger.name}</div>
        </>
    }

    getCapsules() {
        let capsules = [];
        for (let i = 0; i < 60; i++) {
            capsules.push(<div className={styles.capsule} style={{ transform: `rotate(${i * 6}deg)` }}>
                <div className={`${styles.innerCapsule} ${60 - this.state.timer >= i + 1 && styles.fill}`}></div>
            </div>)
        }

        return <div className={styles.capsuleContainer}>
            {capsules}
        </div>
    }

    getScoreColumn(teamIndex) {
        const team = { ...this.state.teams[teamIndex] }
        if (!team) return;

        let scoreBoxes = [];
        let dangerZoneIndex = 0;

        for (let i = 1; i <= this.state.pointsToWin; i++) {
            if (i >= this.state.dangerZoneStart) dangerZoneIndex++;

            scoreBoxes.push(<div key={`scorebox-${team.id}-${i}-${team.score >= i}`} className={`${styles.scoreBox} ${i >= this.state.dangerZoneStart && styles.dangerZone} ${team.score >= this.state.dangerZoneStart && styles.pulse}`}>
                <div className={`${styles.innerBox} ${team.score >= i && styles.highlight} ${this.state.newRuleZones.includes(i) && styles.ruleZone} ${teamIndex === 0 ? styles.pink : styles.black}`}>
                    <div className={`${styles.scoreNum} ${teamIndex === 0 ? styles.pink : styles.black}`}>{i}</div>
                </div>
                {
                    this.state.newRuleZones.includes(i) &&
                    <div className={styles.newRuleMark}>
                        <div className={styles.arrow}></div>
                        <div className={styles.ruleText}>NEW RULE</div>
                    </div>
                }
                {
                    dangerZoneIndex === Math.ceil((this.state.pointsToWin - this.state.dangerZoneStart) / 2) &&
                    <div className={styles.dangerZoneMark}>
                        <img src={teamIndex == 0 ? warningPink : warningBlack} className={styles.warning} />
                        <div className={styles.zoneText}>DANGER ZONE</div>
                    </div>
                }
                <div key={`${teamIndex}-score-${i}-${team.score >= i ? "true" : "false"}`} className={`${styles.pointGained} ${teamIndex === 0 ? styles.teamA : styles.teamB}`}>+1</div>
            </div>);
        }

        return scoreBoxes;
    }

    getRuleList() {
        const team = { ...this.state.teams.find(x => x.id === this.state.describingTeamId) }
        if (!team || !team.rules) return;

        let rules = [];
        team.rules.forEach((x) => {
            if (x != null) {
                rules.push(<div className={`${styles.rule}`}>{`${x.ruleText1} ${x.blockText} ${x.ruleText2}`}</div>)
            }
        });
        return rules;
    }

    async doRoundEnd() {
        await this.sleep(1000);
        this.playBGMusic();
        this.setState({ showRoundView: false, });
        await this.sleep(1000);
        this.playPopSequence(250, 2);
        this.setState({ showTimesUp: true, });
        await this.sleep(4000);
        this.playWoosh();
        this.setState({ showTimesUp: false, });
        await this.sleep(1000);
        this.playPopSequence(250, 4, 500);
        this.setState({ showRuleBreakVote: true, shrinkCenterBox: false, centerTitle: "", centerSmallText: "" });
        this.state.room.send("start_rule_break_vote", {});
    }

    getVotingDescriber() {
        const desciber = this.state.players.find((x) => x.ycsuData.isDescriber);
        if (!desciber) return null;

        return <div className={`${styles.votingPlayer} ${styles.left}`}>
            <div className={`${styles.potato}`}>
                <Lottie
                    options={getAvatarById(desciber.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div>
            <div className={`${styles.name} ${desciber.ycsuData.ready && styles.highlight}`}>{desciber.name}</div>
        </div>
    }

    getVotingDinger() {
        const dinger = this.state.players.find((x) => x.ycsuData.isDinger);
        if (!dinger) return null;

        return <div className={`${styles.votingPlayer} ${styles.right}`}>
            <div className={`${styles.name} ${dinger.ycsuData.ready && styles.highlight}`}>{dinger.name}</div>
            <div className={`${styles.potato}`}>
                <Lottie
                    options={getAvatarById(dinger.avatar).idleAnim}
                    width="100%"
                    height="100%"
                    isClickToPauseDisabled={true}
                />
            </div>
        </div>
    }

    async doCorrectCardsVote() {
        await this.sleep(1000);
        this.playWoosh(250);
        this.setState({ showRuleBreakVote: false });
        await this.sleep(2000);
        this.state.room.send("set_correct_card_vote", {});
    }

    async startCorrectCardsVote(message) {
        this.setState({ correctCards: message.correctCards });
        await this.sleep(1000);
        this.playPopSequence(250, 4, 500);
        this.setState({ showCorrectCardVote: true });
        await this.sleep(1000);
        this.state.room.send("start_correct_card_vote", {});
    }

    getNewRuleVoteSection() {
        let votingTeam = { ...this.state.teams.find(x => x.id !== this.state.newRuleTeamId) };
        let votingTeamIndex = this.state.teams.map(function (e) { return e.id; }).indexOf(votingTeam.id);
        let ruleTeam = { ...this.state.teams.find(x => x.id === this.state.newRuleTeamId) };

        if (!votingTeam || !ruleTeam) return;

        return <>
            <div className={`${styles.timer} ${this.state.showNewRuleVoteSection && this.state.timerRunning && styles.show} ${styles.side}`}>
                <img className={styles.watchButton} src={stopwatchButton} />
                <div className={styles.faceSection}>
                    <img className={styles.watchFace} src={stopwatchFace} />
                    <div className={styles.faceText}>{this.state.timer}</div>
                </div>
            </div>
            <div className={`${styles.ruleCardsSection} ${this.state.showNewRuleVoteSection && styles.show} ${this.state.growNewRuleCard && styles.grow}`}>
                <div className={`${styles.card} ${styles.back} ${!this.state.collapseRuleCards && styles.one} ${!this.state.showFrontRuleCards && styles.hide}`}>
                    <div className={styles.backText}>RULE</div>
                </div>
                <div className={`${styles.card} ${styles.back} ${!this.state.collapseRuleCards && styles.two} ${!this.state.showFrontRuleCards && styles.hide}`}>
                    <div className={styles.backText}>RULE</div>
                </div>
                <div className={`${styles.card} ${!this.state.showNewRuleCard && styles.hide}`} >
                    <div className={styles.ruleText}>{this.state.newRule.ruleText1}</div>
                    <div className={`${styles.blockText} ${this.state.newRule.blockText.length > 15 ? this.state.newRule.blockText.length > 25 ? styles.smaller : styles.medium : null}`}>{this.state.newRule.blockText}</div>
                    <div className={styles.ruleText}>{this.state.newRule.ruleText2}</div>
                </div>
            </div>
            <div className={`${styles.titleGroup} ${this.state.showPickRuleTitle && this.state.showNewRuleVoteSection && styles.show}`}>
                <div className={`${styles.genericTitle} ${this.state.showNewRuleVoteSection && styles.show}`}>{votingTeam.id}</div>
                <div className={`${styles.genericTitle} ${styles.bigger} ${this.state.showNewRuleVoteSection && styles.show}`}>PICK A RULE</div>
                <div className={`${styles.subText} ${this.state.showNewRuleVoteSection && styles.show}`}>{`${votingTeam.id}, pick a new rule for ${ruleTeam.id}.`}</div>
            </div>
            <div className={`${styles.titleGroup} ${this.state.showNewRuleTitle && this.state.showNewRuleVoteSection && styles.show}`}>
                <div className={`${styles.genericTitle} ${this.state.showNewRuleVoteSection && styles.show}`}>{ruleTeam.id}</div>
                <div className={`${styles.genericTitle} ${styles.bigger} ${this.state.showNewRuleVoteSection && styles.show}`}>NEW RULE</div>
                <div className={`${styles.subText} ${this.state.showNewRuleVoteSection && styles.show}`}>{`The describer on ${ruleTeam.id} must stick to the new rule shown.`}</div>
            </div>
            <div className={`${styles.votingPlayerSection} ${this.state.showNewRulePlayers && this.state.showNewRuleVoteSection && styles.show}`}>
                {
                    this.state.players.map((player) => {
                        if (player.ycsuData.teamIndex !== votingTeamIndex) return null;
                        return <div className={`${styles.votingPlayer} ${styles.left}`}>
                            <div className={`${styles.potato}`}>
                                <Lottie
                                    options={getAvatarById(player.avatar).idleAnim}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                            <div className={`${styles.name} ${player.ycsuData.ready && styles.highlight}`}>{player.name}</div>
                        </div>
                    })
                }
                {/*{*/}
                {/*    votingTeam.playerIds?.map((playerId) => {*/}
                {/*        let player = this.state.players.find((x) => x.id === playerId);*/}
                {/*        if (!player) return null;*/}
                {/*        return <div className={`${styles.votingPlayer} ${styles.left}`}>*/}
                {/*            <div className={`${styles.potato}`}>*/}
                {/*                <Lottie*/}
                {/*                    options={getAvatarById(player.avatar).idleAnim}*/}
                {/*                    width="100%"*/}
                {/*                    height="100%"*/}
                {/*                    isClickToPauseDisabled={true}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className={`${styles.name} ${player.ycsuData.ready && styles.highlight}`}>{player.name}</div>*/}
                {/*        </div>*/}
                {/*    })*/}
                {/*}*/}
            </div>
        </>
    }

    getWinnersSection() {
        const winningTeam = { ...this.state.teams.find(x => x.id === this.state.winningTeamId) };
        if (!winningTeam) return;

        return <>
            <img className={`${styles.trophy} ${styles.animate}`} src={trophy} />
            <div className={`${styles.genericTitle} ${styles.animate}`}>{`${winningTeam.id} ARE THE`}</div>
            <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>WINNERS</div>
            <div className={`${styles.subText} ${styles.animate}`}>Great job team!</div>
            <div className={`${styles.votingPlayerSection} ${styles.animate}`}>
                {
                    winningTeam.playerIds?.map((playerId) => {
                        let player = this.state.players.find((x) => x.id === playerId);
                        if (!player) return null;
                        return <div className={`${styles.votingPlayer} ${styles.left}`}>
                            <div className={`${styles.potato}`}>
                                <Lottie
                                    options={getAvatarById(player.avatar).idleAnim}
                                    width="100%"
                                    height="100%"
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                            <div className={`${styles.name}`}>{player.name}</div>
                        </div>
                    })
                }
            </div>
        </>
    }

    async doGameOver() {
        this.setState({ showWinDeciderSection: false, });
        if (this.state.showWinDeciderSection) {
            this.playWoosh();
            await this.sleep(2000);
            this.setState({ showWinDeciderSection: false, });
        } else {
            await this.sleep(1000);
        }
        this.playPopSequence(250, 4, 500);
        this.playAudio(audio.Celebration);
        this.setState({ showWinnerSection: true });
        this.setState({ showWinDeciderSection: false, });
        await this.sleep(7500);
        this.setState({ showWinDeciderSection: false, });
        this.playWoosh(500);
        this.setState({ showWinnerSection: false });
        await this.sleep(1000);
        this.setState({ showPlayAgainButtons: true, moveLogo: false, });
        await this.sleep(500);
        this.state.room.send("reached_end", {});
    }

    async updateTeamError(message) {
        if (message === this.state.teamError) {
            this.setState({ showTeamError: true });
        } else {
            this.setState({ showTeamError: false });
            await this.sleep(600);
            this.setState({ showTeamError: true, teamError: message });
        }

    }

    doTeamMethodSelection() {
        this.setState({ moveLogo: true, showTeamMethodSection: true });
        this.playPopSequence(250, 3, 500);
    }

    async playPopSequence(gap, amount, delay = 0) {
        await this.sleep(delay);
        for (let i = 0; i < amount; i++) {
            this.playAudio(audio.PointPop);
            await this.sleep(gap);
        }
    }

    async playWoosh(delay = 0) {
        await this.sleep(delay);
        this.playAudio(audio.Woosh);
    }

    async doSelectTeams() {
        this.state.room.send("start_select_teams_timer", {});
        if (this.state.showTeamMethodSection || this.state.showTeamsConfirmation) {
            if (this.state.showTeamMethodSection) this.playWoosh(500);
        }
        this.setState({ showTeamsConfirmation: false, showTeamMethodSection: false, });
        await this.sleep(1500);
        this.playAudio(audio.PointPop);
        this.setState({ showChooseTeamSection: true, showTeamSelection: true, });
    }

    async doTeamsConfirm() {
        this.playWoosh();
        this.setState({ showTeamSelection: false, });
        await this.sleep(1000);
        this.playAudio(audio.PointPop);
        this.setState({ showTeamsConfirmation: true, });
        this.state.room.send("start_confirm_teams_timer", {});
    }

    doTeamsOk() {
        this.setState({ showChooseTeamSection: false, showTeamsConfirmation: false, showTeamSelection: false, });
        this.playWoosh();
    }

    async doEndScoreAdjustments() {
        await this.sleep(1000);
        this.playWoosh(500);
        this.setState({ showCorrectCardVote: false });
        await this.sleep(2000);
        this.state.room.send("continue_round", {});
    }

    async doNewRuleVote() {
        await this.sleep(1000);
        this.playPopSequence(0, 1);
        this.setState({ showNewRuleVoteSection: true, showPickRuleTitle: true, showNewRulePlayers: true, showFrontRuleCards: true, });
        await this.sleep(1000);
        this.state.room.send("start_rule_vote", {});
    }

    async doNewRuleVoteEnd(message) {
        this.setState({ newRule: message.newRule });
        await this.sleep(1000);
        this.playWoosh();
        this.setState({ collapseRuleCards: true, showPickRuleTitle: false, showNewRulePlayers: false, });
        await this.sleep(1000);
        this.playAudio(audio.PointPop);
        this.setState({ showNewRuleCard: true, showNewRuleTitle: true, showFrontRuleCards: false, });
        await this.sleep(3000);
        this.playWoosh();
        this.setState({ growNewRuleCard: true, showNewRuleTitle: false, });
        await this.sleep(3000);
        this.playWoosh();
        this.setState({ showNewRuleCard: false, });
        await this.sleep(1000);
        this.setState({ showNewRuleVoteSection: false, });
        await this.sleep(1000);
        this.setState({ growNewRuleCard: false, collapseRuleCards: false, });
        await this.sleep(500);
        this.state.room.send("continue_round", {});
    }

    async playDings(gap, amount, delay = 0) {
        await this.sleep(delay);
        for (let i = 0; i < amount; i++) {
            this.playAudio(audio.Bell);
            await this.sleep(gap);
        }
    }

    playBGMusic() {
        if (!this.state.musicPlaying) {
            this.playAudio(audio.BgMusic);
            this.setState({ musicPlaying: true });
        }
    }

    pauseBGMusic() {
        if (this.state.musicPlaying) {
            this.pauseAudio(audio.BgMusic);
            this.setState({ musicPlaying: false });
        }
    }

    async doWinDecider() {
        this.pauseAudio(audio.RoundMusic);
        await this.sleep(1000);
        this.playBGMusic();
        this.playDings(250, 3);
        this.setState({ showRoundView: false });
        await this.sleep(2000);
        this.playPopSequence(250, 4, 500);
        this.setState({ showWinDeciderSection: true });
    }

    async doContinueRound() {
        this.playWoosh();
        this.pauseBGMusic();
        this.playAudio(audio.RoundMusic);
        this.setState({ showWinDeciderSection: false, });
        await this.sleep(1500);
        this.setState({ showRoundView: true });
    }

    beginTutorial() {
        this.setState({ showTutorial: true, showStartButtons: false, showPlayers: false, moveLogo: true, });
        this.state.room.send("show_tutorial", {});
    }

    getDingerComment() {
        let team = this.state.teams.find((x) => x.id === this.state.describingTeamId);
        let describer = this.state.players.find((x) => x.ycsuData?.isDescriber);
        if (!team || !describer) return;

        if (team.rules.length === 1) {
            return `When you hear ${describer.name} say "Umm", "Err", "Erm", ring the bell.`;
        } else {
            if (team.score >= this.state.dangerZoneStart) {
                return `When anyone on ${team.id} breaks a rule, ring the bell.`;
            } else {
                return `When ${describer.name} breaks a rule, ring the bell.`;
            }
        }
    }

    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                console.log("Host Connection Issue detected");
                this.setState({ connectionIssue: true, });
                LoggingService.streamLog(this.state.logStreamId, "Host Connection Issue Detected at You Cant Say Umm");
                this.hostLostConnectionBug();
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    setupStateListeners(state) {
        state.players.onAdd((player, key) => {
            console.log(player, "has been added at", key);
            this.checkAndAddPlayer(player);

            const changes = ["connected", "votedSkip", "avatar", "name", "id", "primaryPlayer"];
            changes.forEach(change => {
                player.listen(change, (value) => {
                    console.log(`Player ${player.name} Change ${change} to Value ${value}`);
                    let statePlayers = [...this.state.players];
                    let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                    let statePlayer = { ...statePlayers[pos] };

                    statePlayer[change] = value;

                    statePlayers[pos] = statePlayer;
                    this.setState({ players: statePlayers });
                });
            });

            const ycsuPlayerChanges = ["teamIndex", "isDescriber", "isDinger", "ready", "currentRuleVoteId",];
            ycsuPlayerChanges.forEach(change => {
                player.ycsuData.listen(change, (value) => {
                    let statePlayers = [...this.state.players];
                    let pos = statePlayers.map(function (e) { return e.id; }).indexOf(player.id);
                    let statePlayer = { ...statePlayers[pos] };

                    statePlayer.ycsuData[change] = value;

                    statePlayers[pos] = statePlayer;
                    this.setState({ players: statePlayers });
                    if (change === "ready" && value) {
                        this.playAudio(audio.ReadyUp);
                    }
                    if (change === "teamIndex" && value != null && [0, 1].includes(value)) {
                        this.playAudio(audio.VotingPop);
                    }
                });
            });
        });
        state.players.onRemove((player, key) => {
            console.log(player, "has been removed at", key);
            this.removePlayer(player.id);
        });

        const ycsuStateVars = ["gameState", "roundNumber", "maxRounds", "newRuleTeamId", "timer", "timerRunning", "teamSelectionType", "describingTeamId", "currentRuleBreaks", "currentValidationNum", "pointsToWin", "dangerZoneStart", "winningTeamId"];
        ycsuStateVars.forEach((stateVar) => {
            state.ycsuData.listen(stateVar, (change) => {
                let update = {};
                update[stateVar] = change;
                this.setState(update);
                if (stateVar === "timer" && this.state.timerRunning) {
                    if (this.state.gameState === GameStates.Playing || change <= 5) {
                        if (this.state.gameState === GameStates.Playing && change > 15) {
                            this.playAudio(audio.SingleTimerTickQuieter);
                        } else {
                            this.playAudio(audio.SingleTimerTick);
                        }
                    }
                }
            });
        });
        const ycsuStateArrayChanges = ["currentCorrectPairIds", "newRuleZones"];
        ycsuStateArrayChanges.forEach(arrayName => {
            let update = {};
            update[arrayName] = [];
            this.setState(update);
            state.ycsuData[arrayName].onAdd((change, index) => {
                console.log(`YCSU Array ${arrayName} Change ${change} at index ${index}`);
                let array = [...this.state[arrayName]];
                array[index] = change;
                let changeUpdate = {};
                changeUpdate[arrayName] = array;
                this.setState(changeUpdate);
            });
            state.ycsuData[arrayName].onRemove((change, index) => {
                console.log(`YCSU Array ${arrayName} Removed ${change} at index ${index}`);
                let array = [...this.state[arrayName]];
                array[index] = null;
                let changeUpdate = {};
                changeUpdate[arrayName] = array;
                this.setState(changeUpdate);
            });
        });

        state.ycsuData.teams.onAdd((team, index) => {
            console.log("New Team : ", team.id);
            let teams = [...this.state.teams];
            teams[index] = team;
            this.setState({ teams });

            const teamchanges = ["id", "colour", "score", "totalRuleBreaks", "totalCorrectPairs",];
            teamchanges.forEach(change => {
                team.listen(change, (value) => {
                    console.log(`New Team Change : ${change} as ${value}`);
                    let stateTeams = [...this.state.teams];
                    let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                    let stateTeam = { ...stateTeams[pos] };
                    stateTeam[change] = value;
                    stateTeams[pos] = stateTeam;
                    this.setState({ teams: stateTeams });
                    if (change === "score" && value > 0) {
                        this.playAudio(audio.PointPop);
                        if (this.state.gameState === GameStates.Playing) {
                            if (this.state.newRuleZones.includes(value)) {
                                if (value === this.state.newRuleZones[0]) {
                                    this.playAudio(audio.NewRule1);
                                } else if (value === this.state.newRuleZones[1]) {
                                    this.playAudio(audio.NewRule2);
                                }
                            }
                            if (value === this.state.dangerZoneStart) {
                                this.playAudio(audio.DangerZone);
                            }
                        }
                    }
                });
            });

            team.playerIds.onChange((change, index) => {
                console.log(`Team ${team.id} changed Player Id : ${change} at index ${index}`);
                let stateTeams = [...this.state.teams];
                let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                let stateTeam = { ...stateTeams[pos] };
                stateTeam["playerIds"][index] = change;
                stateTeams[pos] = stateTeam;
                this.setState({ teams: stateTeams });
            });
            team.currentCards.onAdd((card, index) => {
                console.log(`Team ${team.id} added new Card : ${card.id} at index ${index}`)
                let stateTeams = [...this.state.teams];
                let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                let stateTeam = { ...stateTeams[pos] };
                stateTeam["currentCards"][index] = card;
                stateTeams[pos] = stateTeam;
                this.setState({ teams: stateTeams });

                const cardChanges = ["id", "cardText"];
                cardChanges.forEach(change => {
                    card.listen(change, (value) => {
                        let cardStateTeams = [...this.state.teams];
                        let cardStateTeam = { ...cardStateTeams[pos] };
                        let currCards = [...cardStateTeam.currentCards];
                        let currCard = { ...currCards[index] };
                        currCard[change] = value;
                        currCards[index] = currCard;
                        cardStateTeam.currentCards = currCards;
                        cardStateTeams[pos] = cardStateTeam;
                        this.setState({ teams: cardStateTeams });
                    });
                });
            });
            team.currentCards.onRemove((card, index) => {
                console.log(`Team ${team.id} Removed Card : ${card.id} at index ${index}`)
                let stateTeams = [...this.state.teams];
                let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                let stateTeam = { ...stateTeams[pos] };
                stateTeam["currentCards"][index] = null;
                stateTeams[pos] = stateTeam;
                this.setState({ teams: stateTeams });
            })
            team.rules.onAdd((rule, index) => {
                console.log(`Team ${team.id} added new Rule ${rule.id} at index ${index}`);
                let stateTeams = [...this.state.teams];
                let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                let stateTeam = { ...stateTeams[pos] };
                stateTeam["rules"][index] = rule;
                stateTeams[pos] = stateTeam;
                this.setState({ teams: stateTeams });

                const ruleChanges = ["id", "ruleText1", "blockText", "ruleText2"];
                ruleChanges.forEach(change => {
                    rule.listen(change, (value) => {
                        let ruleStateTeams = [...this.state.teams];
                        let ruleStateTeam = { ...ruleStateTeams[pos] };
                        let currRules = [...ruleStateTeam.rules];
                        let currRule = { ...currRules[index] };
                        currRule[change] = value;
                        currRules[index] = currRule;
                        ruleStateTeam.rules = currRules;
                        ruleStateTeams[pos] = ruleStateTeam;
                        this.setState({ teams: ruleStateTeams });
                    });
                });
            });
            team.rules.onRemove((rule, index) => {
                console.log(`Team ${team.id} Removed Rule ${rule.id} at index ${index}`);
                let stateTeams = [...this.state.teams];
                let pos = stateTeams.map(function (e) { return e.id; }).indexOf(team.id);
                let stateTeam = { ...stateTeams[pos] };
                stateTeam["rules"][index] = null;
                stateTeams[pos] = stateTeam;
                this.setState({ teams: stateTeams });
            });
        });
        state.ycsuData.teams.onRemove((team, index) => {
            console.log("Team Removed : ", team.id);
            let teams = [...this.state.teams];
            teams[index] = null;
            this.setState({ teams });
        })

        state.ycsuData.currentRulesVote.onAdd((rulesVote, index) => {
            console.log("New rulesVote : ", rulesVote.id);
            let currentRulesVote = [...this.state.currentRulesVote];
            currentRulesVote[index] = rulesVote;
            this.setState({ currentRulesVote });

            const rulesVoteChanges = ["id", "ruleText1", "blockText", "ruleText2"];
            rulesVoteChanges.forEach(change => {
                rulesVote.listen(change, (value) => {
                    console.log(`New Rules Vote Change : ${change} as ${value}`);
                    let stateRulesVotes = [...this.state.currentRulesVote];
                    let stateRulesVote = { ...stateRulesVotes[index] };
                    stateRulesVote[change] = value;
                    stateRulesVotes[index] = stateRulesVote;
                    this.setState({ currentRulesVote: stateRulesVotes });
                });
            });
        });
        state.ycsuData.currentRulesVote.onRemove((rulesVote, index) => {
            console.log("rulesVote Removed: ", rulesVote.id);
            let currentRulesVote = [...this.state.currentRulesVote];
            currentRulesVote[index] = null;
            this.setState({ currentRulesVote });
        })
    }

    doReconnect = () => {
        if (this.state.reconnectTries < 5) {
            const token = this.getQueryStringValue("token");

            if (this.state.connected == false) {
                this.client.reconnect(token).then(room => {
                    console.log(room.sessionId, "joined", room.name);
                    this.setState({ room: room, roomId: room.id, myId: room.sessionId, connected: true, reconnectionToken: room.reconnectionToken });
                    this.updateToken(room.reconnectionToken);
                    room.send("update_host_token", { reconnectionToken: room.reconnectionToken });
                    this.setState({ showLines: true, showStartButtons: true, });

                    room.onStateChange.once((state) => {
                        console.log("this is the first room state!", state);
                        if (state.host.id != room.sessionId) window.location = this.getRedirectURL();
                        this.setState({ roomState: state, });
                        Sentry.setUser({ id: state.host.uniqueId });

                        // this casues everyone to go back to lobby if the host has refreshed their page mid game
                        if (state.ycsuData.gameState === GameStates.Loading) {
                            room.send("host_joined_game", { gameId });
                            this.startLocationChecks();
                        } else {
                            room.send("change_game", {});
                        }

                        this.setupStateListeners(state);
                    });
                    room.onStateChange((state) => {
                        console.log(room.name, "has new state:", state);
                        this.setState({ roomState: state, });
                    });

                    room.onMessage("animate_potato", (message) => {
                        console.log("animate_potato", "received on", room.name, message);
                        this.animatePotato(message.id, message.animation);
                    });

                    room.onMessage("location_confirmed", (message) => {
                        console.log("location_confirmed", "received on", room.name, message);
                        this.setState({ gotLocationPing: true, });
                    });

                    room.onMessage("begin_tutorial", (message) => {
                        console.log("begin_tutorial", "received on", room.name, message);
                        this.beginTutorial();
                    });

                    room.onMessage("end_tutorial", (message) => {
                        console.log("end_tutorial", "received on", room.name, message);
                        this.setState({ showTutorial: false, showPlayers: true, });
                    });
                    room.onMessage("clicked_begin_game", (message) => {
                        console.log("Clicked Begin Game");
                        this.setState({ showStartButtons: false, });
                    });
                    room.onMessage("begin_game", (message) => {
                        console.log("begin_game", "received on", room.name, message);
                        console.log("game state... ", this.state.roomState.ycsuData.gameState);
                        if ((this.state.roomState.ycsuData.gameState == GameStates.Loading || this.state.roomState.ycsuData.gameState == GameStates.Idle) && !this.state.gameBegun) {
                            this.setState({ showTutorial: false, gameBegun: true, showStartButtons: false, });
                            room.send("start_game", {});
                        }
                    });
                    room.onMessage("select_team_method", (message) => {
                        console.log("select_team_method", "received on", room.name, message);
                        this.doTeamMethodSelection();
                    });
                    room.onMessage("select_teams", async (message) => {
                        console.log("select_teams", "received on", room.name, message);
                        this.doSelectTeams();
                    });
                    room.onMessage("confirm_teams", (message) => {
                        console.log("confirm_teams", "received on", room.name, message);
                        this.doTeamsConfirm();
                    });
                    room.onMessage("teams_too_large", (message) => {
                        console.log("teams_too_large", "received on", room.name, message);
                        this.updateTeamError("Teams can't have more than 4 players.");
                    });
                    room.onMessage("teams_too_small", (message) => {
                        console.log("teams_too_small", "received on", room.name, message);
                        this.updateTeamError("Teams must have at least 2 players.");
                    });
                    room.onMessage("team_sizes_ok", (message) => {
                        console.log("team_sizes_ok", "received on", room.name, message);
                        this.setState({ showTeamError: false });
                    });
                    room.onMessage("teams_ok", (message) => {
                        console.log("teams_ok", "received on", room.name, message);
                        this.doTeamsOk();
                    });

                    room.onMessage("start_round", (message) => {
                        console.log("start_round", "received on", room.name, message);
                        this.doStartRound();
                    });
                    room.onMessage("all_ready", (message) => {
console.log("all_ready", "received on", room.name, message);
                        this.doAllReady();
                    });
                    room.onMessage("not_enough_players", (message) => {
                        console.log("not_enough_players", "received on", room.name, message);
                        this.doNotEnoughPlayers(message);
                    });
                    room.onMessage("round_end", (message) => {
                        console.log("round_end", "received on", room.name, message);
                        this.doRoundEnd();
                    });
                    room.onMessage("start_correct_cards_vote", (message) => {
                        console.log("start_correct_cards_vote", "received on", room.name, message);
                        this.doCorrectCardsVote();
                    });
                    room.onMessage("set_correct_cards_vote", (message) => {
                        console.log("set_correct_cards_vote", "received on", room.name, message);
                        this.startCorrectCardsVote(message);
                    });
                    room.onMessage("end_score_adjustments", (message) => {
                        console.log("end_score_adjustments", "received on", room.name, message);
                        this.doEndScoreAdjustments();
                    });
                    room.onMessage("new_rule_vote", (message) => {
                        console.log("new_rule_vote", "received on", room.name, message);
                        this.doNewRuleVote();
                    });
                    room.onMessage("new_rule_vote_end", (message) => {
                        console.log("new_rule_vote_end", "received on", room.name, message);
                        this.doNewRuleVoteEnd(message);
                    });
                    room.onMessage("win_decider", (message) => {
                        console.log("win_decider", "received on", room.name, message);
                        this.doWinDecider();
                    });
                    room.onMessage("ding_ding", (message) => {
                        console.log("ding_ding", "received on", room.name, message);
                        this.playAudio(audio.LoudBell);
                    });
                    
room.onMessage("correct_guess", (message) => {
                        console.log("correct_guess", "received on", room.name, message);
                        this.playAudio(audio.GuessingPoint);
});
                    
                    room.onMessage("passing_card", (message) => {
                        console.log("passing_card", "received on", room.name, message);
                        this.playAudio(audio.PassingCard);
                    });
                    room.onMessage("vote_change", (message) => {
                        console.log("vote_change", "received on", room.name, message);
                        this.playAudio(audio.VotingPop);
                    });
                    room.onMessage("continue_round", (message) => {
                        console.log("continue_round", "received on", room.name, message);
                        this.doContinueRound();
                    });
                    room.onMessage("game_over", (message) => {
                        console.log("game_over", "received on", room.name, message);

                        this.doGameOver();
                    });

                    room.onMessage("change_game", (message) => {
                        console.log("change_game", "received on", room.name, message);
                        this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}/?token=${this.state.reconnectionToken}` });
                        this.state.room.leave(false);
                    });

                    room.onMessage("new_game", async (message) => {
                        console.log("new_game", "received on", room.name, message);
                        this.setState({ showPlayAgainButtons: false });
                        await this.sleep(1000);
                        this.state.room.send("start_game", {});
                    });

                    room.onError((code, message) => {
                        console.log(this.client.id, "couldn't join", room.name);
                        //LoggingService.logError(message, code);
                    });
                    room.onLeave((code) => {
                        console.log(this.client.id, "left", room.name);
                        if (!this.state.redirect) {
                            this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                            setTimeout(() => {
                                this.doReconnect();
                            }, 1000);
                        }
                    });
                }).catch(e => {
                    console.log("JOIN ERROR", e);
                    this.setState({ connected: false, reconnectTries: this.state.reconnectTries + 1 });
                    setTimeout(() => {
                        this.doReconnect();
                    }, 1000);
                });
            }
        } else {
            this.setState({ redirect: `${this.getRedirectURL()}${this.state.roomState.isRoku ? "/roku" : "/lobby"}` });
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        }
    }

    render() {
        if (this.state.redirect) {
            return (
                <React.Fragment>
                    <div id="gameContainer" className={styles.gameContainer}>
                        <div className={styles.loadingContainer}>
                            <Loading loadingText={"Sending you to the lobby!"} />
                        </div>
                    </div>
                    <div style={{ opacity: 0 }}>
                        <Route path="/" render={() => (window.location = this.state.redirect)} />
                    </div>'
                </React.Fragment>
            )
        }
        return (
            <div>
                <audio ref />
                <div id="gameContainer" className={styles.gameContainer}>
                    {
                        !this.state.connected ?
                            <div className={styles.logoSection}>
                                <img src={logo} className={styles.logo} />
                            </div>
                            :
                            <React.Fragment>
                                <Menu room={this.state.room} toggleMute={this.toggleMute} toggleMenu={this.toggleMenu} menuOpen={this.state.menuOpen} muted={this.state.muted} />
                                {
                                    this.state.showTutorial &&
                                    <Tutorial room={this.state.room} players={this.state.players} />
                                }
                                {/*<div style={{ zIndex: 20, }}>*/}
                                {/*    {*/}
                                {/*        this.state.doConfetti &&*/}
                                {/*        <Confetti*/}
                                {/*            width={window.innerWidth}*/}
                                {/*            height={window.innerHeight}*/}
                                {/*            initialVelocityY={20}*/}
                                {/*            numberOfPieces={1500}*/}
                                {/*            recycle={false}*/}
                                {/*            confettiSource={{ x: window.innerWidth / 2, y: window.innerHeight + 10, width: window.innerWidth + 10, height: 0 }}*/}
                                {/*            initialVelocityY={{ min: -10, max: -30, }}*/}
                                {/*            initialVelocityX={{ min: -10, max: 10, }}*/}
                                {/*            onConfettiComplete={() => this.setState({ doConfetti: false, })}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className={styles.roomCode}>
                                    <div className={styles.textBox}>
                                        <div className={styles.text}>{this.getRedirectURL(true)}</div>
                                        <div className={styles.text}>Code: <span className={`${styles.text} ${styles.code}`}>{this.state.roomId}</span></div>
                                        <div className={styles.iconsBox}>
                                            <div className={styles.muteToggle} onClick={() => this.toggleMute()}>
                                                <img src={this.state.muted ? mutedIcon : unmutedIcon} className={styles.muteIcon} />
                                            </div>
                                            {
                                                fullscreenAvailable ?
                                                    <div className={styles.muteToggle} onClick={() => { this.toggleFullScreen() }}>
                                                        <img src={fullscreenIcon} className={styles.muteIcon} />
                                                    </div>
                                                    :
                                                    null
                                            }
                                            <div className={styles.muteToggle} onClick={() => this.toggleMenu()}>
                                                <img src={helpIcon} className={styles.muteIcon} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.qrCodeBox}>
                                        <QRCode
                                            className={styles.qrCode}
                                            value={`${this.getRedirectURL()}/play/?qrCode=${this.state.roomId}`}
                                            bgColor="rgba(0,0,0,0)"
                                        />
                                    </div>
                                </div>
                                <div className={`${styles.logoSection}`}>
                                    <img src={logo} className={`${styles.logo} ${this.state.moveLogo ? styles.topLeft : ""}`} />
                                </div>
                                <div className={`${styles.linesSection} ${this.state.showLines && styles.show}`}>
                                    <img src={checkStrip} className={`${styles.line} ${styles.top}`} />
                                    <img src={checkStrip} className={`${styles.line} ${styles.bottom}`} />
                                </div>
                                {
                                    this.state.showStartButtons &&
                                    <div className={styles.startButtonSection}>
                                        <button className={`${styles.mainButton}`} onClick={() => this.signalStartGame(true)}>
                                            Start Game
                                        </button>
                                        <button className={`${styles.mainButton}`} onClick={() => this.signalStartGame(false)}>
                                            Show Tutorial
                                        </button>
                                        <button className={`${styles.mainButton} ${styles.alt}`} onClick={this.goToLobby}>
                                            Go To Lobby
                                        </button>
                                    </div>
                                }

                                <div className={`${styles.teamMethodSection} ${this.state.showTeamMethodSection && styles.show}`}>
                                    <div className={`${styles.timer} ${this.state.showTeamMethodSection && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>CHOOSE TEAM SELECTION</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>METHOD</div>
                                    <div className={`${styles.buttonsGroup} ${styles.animate}`}>
                                        <button className={`${styles.mainButton}`} onClick={() => this.clickTeamMethod("manual")}>Manually Select</button>
                                        <button className={`${styles.mainButton} ${styles.alt}`} onClick={() => this.clickTeamMethod("random")}>Select Randomly</button>
                                    </div>
                                </div>

                                <div className={`${styles.chooseTeamSection} ${this.state.showChooseTeamSection && styles.show}`}>
                                    <div className={`${styles.timer} ${this.state.showChooseTeamSection && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.teamTitleGroup} ${this.state.showTeamSelection && styles.show}`}>
                                        <div className={`${styles.subText} ${styles.smaller} ${this.state.showTeamError && styles.show}`} style={{ top: "-8vh", position: "absolute", left: 0, right: 0 }}>{this.state.teamError}</div>
                                        <div className={`${styles.genericTitle} ${styles.show}`}>{this.state.teamSelectionType === "manual" ? "CHOOSE TEAM" : "LET US SORT THE"}</div>
                                        <div className={`${styles.genericTitle} ${styles.bigger} ${styles.show}`}>{this.state.teamSelectionType === "manual" ? "A OR B" : "TEAMS"}</div>
                                        <div className={`${styles.subText} ${styles.show}`}>{this.state.teamSelectionType === "manual" ? "Choose a team on your mobile device." : "Take note of who's on your team."}</div>
                                        <div className={styles.emptyPlayerPool}>
                                            {
                                                this.state.players.map((x, i) => {
                                                    return <div className={`${styles.emptyPlayer} ${x.name.length > 8 && styles.small} ${[0, 1].includes(x.ycsuData.teamIndex) && styles.hide}`}>{x.name}</div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles.teamTitleGroup} ${this.state.showTeamsConfirmation && styles.show}`}>
                                        <div className={`${styles.genericTitle} ${styles.show}`}>IS EVERYBODY</div>
                                        <div className={`${styles.genericTitle} ${styles.bigger} ${styles.show}`}>HAPPY</div>
                                        <div className={`${styles.buttonsGroup} ${styles.show}`}>
                                            <button className={`${styles.mainButton}`} onClick={() => this.clickTeamConfirmation(true)}>Yes</button>
                                            <button className={`${styles.mainButton} ${styles.alt}`} onClick={() => this.clickTeamConfirmation(false)}>No</button>
                                        </div>
                                    </div>
                                    <div className={`${styles.teamSection} ${styles.teamA} ${styles.animate}`}>
                                        <div className={styles.title}>TEAM A</div>
                                        <div className={styles.teamBox}>
                                            {
                                                this.state.players.filter((x, i) => x.ycsuData.teamIndex === 0).map((y, i) => {
                                                    return <div className={`${styles.teamMember} ${styles.animate} ${y.name.length > 8 && styles.small}`}>{y.name}</div>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className={`${styles.teamSection} ${styles.teamB} ${styles.animate}`}>
                                        <div className={styles.title}>TEAM B</div>
                                        <div className={styles.teamBox}>
                                            {
                                                this.state.players.filter((x, i) => x.ycsuData.teamIndex === 1).map((y, i) => {
                                                    return <div className={`${styles.teamMember} ${styles.animate} ${y.name.length > 8 && styles.small}`}>{y.name}</div>
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showDescriber && styles.show}`}>
                                    {/*<div className={`${styles.playerBox} ${styles.animate}`}>*/}
                                    {/*    {this.getDescriber()}*/}
                                    {/*</div>*/}
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate} ${styles.medium}`}>{this.getDescriber()}</div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>YOU WILL BE THE</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>DESCRIBER</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>The rest of {this.state.teams.find(x => x.id === this.state.describingTeamId)?.id}, try to guess the combination.</div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showDinger && styles.show}`}>
                                    {/*<div className={`${styles.playerBox} ${styles.animate}`}>*/}
                                    {/*    {this.getDinger()}*/}
                                    {/*</div>*/}
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate} ${styles.medium}`}>{this.getDinger()}</div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>YOU'RE IN CHARGE OF THE</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>DINGER</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>{this.getDingerComment()}</div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showNotEnoughPlayersSection && styles.show}`}>
                                    <div className={`${styles.timer} ${this.state.showNotEnoughPlayersSection && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>NOT ENOUGH PLAYERS</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>TO START</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>Please reconnect or the game will end...</div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showReadyCheck && styles.show}`}>
                                    <div className={`${styles.timer} ${this.state.showReadyCheck && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>TEAMS ARE YOU READY</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>TO START?</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>Ready up on your devices when you're ready to begin!</div>
                                    <div className={`${styles.votingPlayerSection} ${styles.animate}`}>
                                        {
                                            this.getVotingDescriber()
                                        }
                                        {
                                            this.getVotingDinger()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.roundView} ${this.state.showRoundView && styles.show}`}>
                                    <img src={roundBG} className={styles.roundBG} />
                                    <div className={`${styles.teamSection} ${styles.left}`}>
                                        <div className={`${styles.playerColumn}`}>
                                            <div className={`${styles.teamTitle} ${styles.teamA}`}>TEAM A</div>
                                            {
                                                this.state.players.map((x, index) => {
                                                    if (x.ycsuData.teamIndex === 0) {
                                                        return <Player player={x} left={true} />
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className={styles.scoreColumn}>
                                            {
                                                this.getScoreColumn(0)
                                            }
                                            <img src={flagPink} className={`${styles.flag}`} />
                                        </div>
                                    </div>
                                    <div className={`${styles.teamSection} ${styles.right}`}>

                                        <div className={`${styles.playerColumn}`}>
                                            <div className={`${styles.teamTitle} ${styles.teamB}`}>TEAM B</div>
                                            {
                                                this.state.players.map((x, index) => {
                                                    if (x.ycsuData.teamIndex === 1) {
                                                        return <Player player={x} />
                                                    }
                                                })
                                            }
                                        </div>
                                        <div className={styles.scoreColumn}>
                                            {
                                                this.getScoreColumn(1)
                                            }
                                            <img src={flagBlack} className={`${styles.flag}`} />
                                        </div>
                                    </div>
                                    <div className={`${styles.centerBox} ${this.state.shrinkCenterBox && styles.shrink}`}>
                                        <div className={`${styles.innerBorder}`}></div>
                                        <div key={`centerTitle-${this.state.centerTitle}`} className={`${styles.title}`}>{this.state.centerTitle}</div>
                                        <div key={`centerSmallText-${this.state.centerSmallText}`} className={`${styles.title} ${styles.smaller}`}>{this.state.centerSmallText}</div>
                                    </div>
                                    <div className={styles.bigWatch}>
                                        <img className={styles.watchButton} src={bigWatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={bigWatchFace} />
                                            <div key={`bigWatchTimer-${this.state.timer}`} className={styles.faceText}>{this.state.timer}</div>
                                            {this.getCapsules()}
                                        </div>
                                    </div>
                                    <div className={styles.ruleList}>
                                        {
                                            this.getRuleList()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showTimesUp && styles.show}`}>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>OK FOLKS</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>TIMES UP</div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showRuleBreakVote && styles.show}`}>
                                    {/*<div className={`${styles.playerBox} ${styles.animate}`}>*/}
                                    {/*    {this.getDescriber()}*/}
                                    {/*</div>*/}
                                    <div className={`${styles.timer} ${this.state.showRuleBreakVote && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate} ${styles.medium}`}>{this.getDescriber()}</div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>BROKE THE RULES</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>{`${this.state.currentValidationNum === 0 ? "NEVER" : this.state.currentValidationNum === 1 ? "ONCE" : this.state.currentValidationNum === 2 ? "TWICE" : this.state.currentValidationNum + " TIMES"}`}</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>is this correct?</div>
                                    <div className={`${styles.votingPlayerSection} ${styles.animate}`}>
                                        {
                                            this.getVotingDescriber()
                                        }
                                        {
                                            this.getVotingDinger()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showCorrectCardVote && styles.show}`}>
                                    <div className={`${styles.timer} ${this.state.showCorrectCardVote && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>{`${this.state.teams.find(x => x.id === this.state.describingTeamId)?.id} GUESSED THESE WORDS`}</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>{this.state.currentValidationNum} Correct {this.state.currentValidationNum === 1 ? "Pair" : "Pairs"}</div>
                                    <div className={`${styles.voteCorrectCardsSection} ${styles.animate}`}>
                                        {
                                            this.state.correctCards.map((x, i, a) => {
                                                if (i % 2 === 1) return;
                                                return <div className={`${styles.cardGroup} ${a.length > 12 && styles.smaller}`}>
                                                    <div className={`${styles.card} ${styles.one}`}>{x.cardText}</div>
                                                    <div className={`${styles.card} ${styles.two}`}>{this.state.correctCards[i + 1].cardText}</div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={`${styles.votingPlayerSection} ${styles.animate}`}>
                                        {
                                            this.getVotingDescriber()
                                        }
                                        {
                                            this.getVotingDinger()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showNewRuleVoteSection && styles.show}`}>
                                    {
                                        this.getNewRuleVoteSection()
                                    }
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showWinDeciderSection && styles.show}`}>
                                    <div className={`${styles.timer} ${this.state.showWinDeciderSection && this.state.timerRunning && styles.show}`}>
                                        <img className={styles.watchButton} src={stopwatchButton} />
                                        <div className={styles.faceSection}>
                                            <img className={styles.watchFace} src={stopwatchFace} />
                                            <div className={styles.faceText}>{this.state.timer}</div>
                                        </div>
                                    </div>
                                    <div className={`${styles.genericTitle} ${styles.animate}`}>{`LOOKS LIKE ${this.state.teams.find(x => x.id === this.state.winningTeamId)?.id}`}</div>
                                    <div className={`${styles.genericTitle} ${styles.bigger} ${styles.animate}`}>HAVE WON</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>is this correct?</div>
                                    <div className={`${styles.subText} ${styles.animate}`}>Points Earned {this.state.currentValidationNum}</div>
                                    <div className={`${styles.votingPlayerSection} ${styles.animate}`}>
                                        {
                                            this.getVotingDescriber()
                                        }
                                        {
                                            this.getVotingDinger()
                                        }
                                    </div>
                                </div>
                                <div className={`${styles.describerSection} ${this.state.showWinnerSection && styles.show}`}>
                                    {
                                        this.getWinnersSection()
                                    }
                                </div>
                                {
                                    this.state.showPlayAgainButtons &&
                                    <div className={styles.startButtonSection}>
                                        <button className={`${styles.mainButton}`} onClick={this.signalNewGame}>
                                            Play Again
                                        </button>
                                        <button className={`${styles.mainButton} ${styles.alt}`} onClick={this.goToLobby}>
                                            Return to lobby
                                        </button>
                                    </div>
                                }
                                {
                                    this.state.testMode &&
                                    <div style={{ position: "absolute", display: "flex", flexDirection: "column", zIndex: 10000 }}>
                                        <button onClick={() => this.setState({ moveLogo: !this.state.moveLogo })}>moveLogo</button>
                                        <button onClick={() => this.setState({ showStartButtons: !this.state.showStartButtons })}>showStartButtons</button>
                                        <button onClick={() => this.setState({ showLines: !this.state.showLines })}>showLines</button>
                                        <button onClick={() => this.doTeamMethodSelection()}>doTeamMethodSelection</button>
                                        <button onClick={() => this.doSelectTeams()}>doSelectTeams</button>
                                        <button onClick={() => this.doTeamsConfirm()}>doTeamsConfirm</button>
                                        <button onClick={() => this.doTeamsOk()}>doTeamsOk</button>
                                        <button onClick={() => this.doNotEnoughPlayers()}>doNotEnoughPlayers</button>
                                        <button onClick={() => this.doStartRound()}>doStartRound</button>
                                        <button onClick={() => this.doRoundEnd()}>doRoundEnd</button>
                                        <button onClick={() => this.doCorrectCardsVote()}>doCorrectCardsVote</button>
                                        <button onClick={() => this.startCorrectCardsVote({ correctCards: [{ i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" }, { i: 1, cardText: "Banana" }, { i: 2, cardText: "Apple" },] })}>startCorrectCardsVote</button>
                                        <button onClick={() => this.doEndScoreAdjustments()}>doEndScoreAdjustments</button>
                                        <button onClick={() => this.doNewRuleVote()}>doNewRuleVote</button>
                                        <button onClick={() => this.doNewRuleVoteEnd({ newRule: { id: 1, ruleText1: "You can't say", blockText: "Umm", ruleText2: "" } })}>doNewRuleVoteEnd</button>
                                        <button onClick={() => this.doGameOver()}>doGameOver</button>
                                        <button onClick={() => this.beginTutorial()}>beginTutorial</button>
                                        {/*<button onClick={() => this.setState({ centerSmallText: "READY" })}>centerSmallText "READY"</button>*/}
                                        {/*<button onClick={() => this.setState({ collapseRuleCards: !this.state.collapseRuleCards })}>collapseRuleCards</button>*/}
                                        {/*<button onClick={() => this.setState({ showNewRuleCard: !this.state.showNewRuleCard })}>showNewRuleCard</button>*/}
                                        {/*<button onClick={() => this.setState({ growNewRuleCard: !this.state.growNewRuleCard })}>growNewRuleCard</button>*/}
                                        {/*<button onClick={() => this.setState({ centerSmallText: "STEADY" })}>centerSmallText "STEADY"</button>*/}
                                        {/*<button onClick={() => this.setState({ shrinkCenterBox: !this.state.shrinkCenterBox, centerSmallText: "GO" })}>shrinkCenterBox</button>*/}


                                        {/*<button onClick={() => { let timer = this.state.timer; timer--; this.setState({ timer }) }}>Reduce Timer</button>*/}
                                    </div>
                                }
                            </React.Fragment>
                    }
                </div>
                {/*{*/}
                {/*    this.state.connected == false &&*/}
                {/*    <div style={{ position: "absolute", display: "flex", flexDirection: "column", zIndex: 10000 }}>*/}

                {/*        <button onClick={() => this.setState({ ...this.state, ...testRoomState, connected: true, testMode: true, })}>Load Test Data</button>*/}
                {/*    </div>*/}
                {/*}*/}

            </div >
        );
    }
}